export const UPDATE_ROOM_STATE = "UPDATE_ROOM_STATE";
export const UPDATE_ROUND_STATE = "UPDATE_ROUND_STATE";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const UPDATE_ENTRIES_STATE = "UPDATE_ENTRIES_STATE";
export const UPDATE_MINIGAMES_STATE = "UPDATE_MINIGAMES_STATE";
export const UPDATE_SESSION_STATE = "UPDATE_SESSION_STATE";
export const UPDATE_DRAW_STATE = "UPDATE_DRAW_STATE";
export const UPDATE_NEW_ENTRY_STATE = "UPDATE_NEW_ENTRY_STATE";
export const UPDATE_FILLING_IN_BLANKS_STATE = "UPDATE_FILLING_IN_BLANKS_STATE";
export const UPDATE_ROUND_STAGE_STATE = "UPDATE_ROUND_STAGE_STATE";
export const UPDATE_CRITIC_STATE = "UPDATE_CRITIC_STATE";
export const UPDATE_ROUND_INFO_STATE = "UPDATE_ROUND_INFO_STATE";
