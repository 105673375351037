export const iconStyle = {
  top: "2px",
  position: "relative",
  margin: "2px"
};
export const buttonTextStyle = {
  margin: "2px"
};
export const centerRowContentStyle = {
  marginLeft: 0,
  marginRight: 0,
  textAlign: "center",
  marginBottom: "5px"
};

export const centerTitleContentStyle = {
  textAlign: "center",
  margin: "15px",
  fontSize: "medium",
};

export const alertStyle = {
  display: "inline-block",
  marginBottom: 0,
  padding: "1vh 2vw",
  margin: "1vh 2vw"
};

export const inputStyle = {
  outline: "thin",
  padding: "0.5vh 0.5vw",
  margin: "1vh 1vw"
}

export const formHolderStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "3vh 1vw"
}

export const formStyle = {
  padding: "2vh 1vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "white",
  border: "1px solid lightgray",
  width: "fit-content",
  gap: "1vh"
}

export const inputHolderStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 3vw"
};

export const craicboxDarkGreen = "#2d925f";
export const craicboxGreen = "#43b659";
export const craicboxOrange = "#ee6549";
export const craicboxPurple = "rgb(64, 85, 202)";
