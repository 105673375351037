import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

let sessionUrl = null;

export const setupLogRocket = () => {
  const isDisabledViaConfig = localStorage.getItem("logRocketDisabled") === "true";
  const isQa = window.location.href.includes("qa.craicbox.app");
  const isEnabled = process.env.NODE_ENV == "production" && !isDisabledViaConfig && !isQa;
  console.log(`LogRocket enabled: ${isEnabled}. ` + 
    `[NODE_ENV=${process.env.NODE_ENV}, isDisabledViaConfig=${isDisabledViaConfig}, ` +
    `isQa=${isQa}]`);

  if (isEnabled) {
    LogRocket.init('zi2jjz/craicbox');
    LogRocket.getSessionURL((sessionURL) => {
      console.log(`LogRocket session URL: ${sessionURL}`);
      sessionUrl = sessionURL;
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('logrocket_url', sessionURL);
      }
    });
    setupLogRocketReact(LogRocket);
  } else {
    console.log("Disabled LogRocket");
  }
};

export const getLogRocketHeaders = () => {
  return {
    'X-LogRocket-URL': sessionUrl 
  };
};
