import React, { useState } from 'react';
import Modal from 'react-modal';
import CraicboxTitle from '../../images/CraicboxTitle.png';
import { Alert, Checkbox } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { alertStyle, craicboxDarkGreen } from '../../constant/Styles';

function ConsentForm(props) {

  const [isOpen, setIsOpen] = useState(localStorage.getItem('cookiesAndTermsAccepted') !== 'true');
  const isDesktop = window.matchMedia("(min-width: 768px)").matches;
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const isContinueEnabled = cookiesAccepted && termsAccepted;
  const onContinue = () => {
    localStorage.setItem('cookiesAndTermsAccepted', true);
    setIsOpen(false);
  }

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "medium"
  }
  const imageStyle = {
    textAlign: "center",
    width: isDesktop ? "30vw" : "70vw"
  };
  const titleImageStyle = {
    width: isDesktop ? "30vw" : "70vw"
  };
  const checkboxStyle = {
    fontSize: "large"
  };
  const buttonStyle = {
    fontSize: "large",
    marginBottom: "1vh",
    padding: "0.5vh 1vw",
    backgroundColor: isContinueEnabled ? craicboxDarkGreen : "lightgrey"
  };
  const linkStyle = {
    margin: "1vh 1vw"
  };
  Modal.setAppElement("#root");
  return (
    <div>
      <Modal isOpen={isOpen} >
        <div style={modalStyle}>
          <img src={CraicboxTitle} style={titleImageStyle} alt="Craicbox Title"></img>
          <img style={imageStyle} src={"/img/logo-no-background.png"} alt="Craicbox Logo"></img>
          <p>
            Craicbox requires cookies to function and improve.
          </p>
          <div>
            <Checkbox style={checkboxStyle} checked={cookiesAccepted} onChange={(evt) => setCookiesAccepted(evt.target.checked)}>
             I consent to the required cookies
            </Checkbox>
            <Checkbox style={checkboxStyle} checked={termsAccepted} onChange={(evt) => setTermsAccepted(evt.target.checked)}>
            I consent to the terms and conditions
            </Checkbox>
          </div>
          { isContinueEnabled ||
            <Alert bsStyle="warning" style={alertStyle}>
              You must accept cookies and terms to continue.
            </Alert> }
          <button style={buttonStyle} type="button" disabled={!isContinueEnabled} onClick={onContinue}>
            Continue
          </button>
          <Link style={linkStyle} to="/privacy-policy">Privacy Policy</Link>
          <Link style={linkStyle} to="/terms-and-conditions">Terms and Conditions</Link>
        </div>
     </Modal>
      { props.content }
    </div>);
}

export default ConsentForm;
