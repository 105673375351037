export const setupNewRelic = () => {
  const interval = setInterval(() => {
    if (window.newrelic) {
      clearInterval(interval);
      logNewRelicSessionId();
    }
  }, 500); // Check every 500 milliseconds
};

const logNewRelicSessionId = () => {
  try {
    Object.values(window.newrelic.initializedAgents)
      .map(agent => {
        console.log(`NewRelic session ID: ${agent.runtime.session.state.value}`);
      });
  } catch (err) {
    console.log(`Could not determine newrelic session ID: ${err}`);
  }
};
