import React, { useState, useEffect } from "react";
import Logo from "../../images/Logo.png";

function Loading() {
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
  }, []);

  const textStyle = {
    textAlign: "center",
    fontSize: "xx-large",
    marginTop: "25vh"
  };
  const imageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    width: isDesktop ? "25%" : "50%"
  };
  return (<div>
      <div style={textStyle}>Loading...</div>
      <img style={imageStyle} src={Logo} alt="Logo"></img>
    </div>);
}

export default Loading;