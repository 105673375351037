
import { reactiveReducer } from "./ReactiveReducer";
import stateReducer from "./StateReducer";

// Rrocess the action through each of the provided reducers in sequence, 
// updating the state accordingly
const chainReducers = (...reducers) => (state, action) => {
  return reducers.reduce((currentState, reducer) => {
    return reducer(currentState, action);
  }, state);
};

const initialState = {
  room: null,
  round: null,
  user: null,
  entries: [],
  minigames: [],
  session: {
    roomCode: null,
    isLoggedIn: false
  },
  draw: { hasSnapshot: false, snapshot: null },
  newEntry: null,
  fillingInBlanks: { sentenceBlanks: [] },
  critic: {},
  roundInfo: {}
};
const rootReducer = chainReducers(
  (state = initialState, _) => state,
  reactiveReducer, 
  stateReducer);

export default rootReducer;
