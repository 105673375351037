import Config from "../constant/Config";
import { handleRequest } from "../utils";

export const extractAccountFromUrl = () => {
  const accountUuid = new URLSearchParams(window.location.search).get("accountUuid");
  if (accountUuid !== null) {
    localStorage.setItem("accountUuid", accountUuid);
  }
}

export const getAccount = async () => {
  try {
    let accountUuid = localStorage.getItem("accountUuid");
    if (accountUuid !== null) {
      try {
        const accountJson = await handleRequest(
          "GET", `${Config.apiurl}/account/${accountUuid}`, "Can't get existing account")
        return accountJson;
      } catch (err) {
        if (err.message.includes("Account does not exist.")) { 
          console.warn("Account does not exist so creating a new one.")
        } else {
          throw err;
        }
      }
    }
    const newAccountJson = await handleRequest(
      "POST", `${Config.apiurl}/account`, "Can't create new account")
    localStorage.setItem("accountUuid", newAccountJson.accountUuid);
    return newAccountJson;
  } catch (err) {
    console.log("Failed to get or create account", err);
    throw err;
  }
}
