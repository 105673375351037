import { UPDATE_ROOM_STATE, UPDATE_ROUND_STATE } from './ActionTypes';

const initialState = {
  room: null,
};
// This is a reducer which updates the state of the app based 
// on a different part of the state. 
// So far it has one responsibility: kick the user from the room if necessary.
export const reactiveReducer = (state = initialState, action) => {
  let newState = { ...state };
  if (shouldUserBeKickedFromRoom(state, action)) {
    console.log("Kicking user because they were in the room but are no longer. " +
      `[UserId: ${state.user.userId}, ` +
      `NewRoom: ${JSON.stringify(action.payload)}, ` +
      `PreviousRoom: ${JSON.stringify(state.room)}]`);
    newState = { ...state, session: { ...state.session, isLoggedIn: false } };
  }
  if (shouldClearNewEntry(state, action)) {
    newState = { ...state, newEntry: null };
  }
  if (shouldClearRoundRelatedState(state, action)) {
    console.log("Clearing round related state. " + 
      `[RoundId: ${action.payload.currentRoundId}]`);
    newState = { 
      ...state, 
      draw: { hasSnapshot: false, snapshot: null },
      fillingInBlanks: { sentenceBlanks: [] },
      critic: {},
      roundInfo: {}
    };
  }

  if (shouldClearRoundStageRelatedState(state, action)) {
    console.log("Clearing round stage related state. " + 
      `[RoundId: ${action?.payload?.roundId}, ` +
      `RoundStageId: ${action?.payload?.roundStageId}]`);
    newState = { 
      ...state, 
      draw: { hasSnapshot: false, snapshot: null },
      fillingInBlanks: { sentenceBlanks: [] },
      critic: {},
      roundInfo: {}
    };
  }
  return newState;
};

const shouldUserBeKickedFromRoom = (state, action) => {
  if (!state.room || !state.room.roomId || !state.user || !state.user.userId) {
    return false;
  }

  if (action.type === UPDATE_ROOM_STATE &&
    state.room.roomId === action.payload.roomId &&
    isUserInRoom(state.room, state.user.userId) &&
    !isUserInRoom(action.payload, state.user.userId)) {
    return true;
  }

  return false;
}

const isUserInRoom = (room, userId) => {
  return room &&
    room.roomUsers &&
    room.roomUsers
      .filter((ru) => ru.userId === userId)
      .length > 0;
}

const shouldClearNewEntry = (state, action) => {
  return action.type === UPDATE_ROOM_STATE &&
    state.room?.minigameId &&
    action.payload.minigameId !== state.room.minigameId;
}

const shouldClearRoundRelatedState = (state, action) => {
  return action.type === UPDATE_ROOM_STATE &&
    state.room?.currentRoundId &&
    action.payload.currentRoundId !== state.room.currentRoundId;
}

const shouldClearRoundStageRelatedState = (state, action) => {
  return action.type === UPDATE_ROUND_STATE &&
    state.round?.roundStageId &&
    action.payload?.roundStageId !== state.round.roundStageId;
}
